<template>
  <el-row :gutter="20" class="pro-container pro-base-msg">
    <el-col :span="2">
      <!-- 基本信息 -->
      <h6 class="left-h6">{{ $t('productDetail.BasicInformation') }}</h6>
    </el-col>
    <el-col :span="22" class="right-content">
      <el-form ref="form" :rules="rules" :model="form" label-width="110px" class="el_form"
        :label-position="settings === 'zh' ? 'right' : 'top'">
        <el-row :gutter="20">
          <el-col :span="12">
            <!-- 英文名称 -->
            <el-form-item class="w100" :label="$t('productDetail.EnglishName')" prop="nameEn">
              <!-- 请输入长度1到100个字符 -->
              <el-input v-model="form.nameEn" @input="nameEnblur" :placeholder="$t('placeholder.M2001')" maxlength="100"
                show-word-limit class="test">
                <template slot="append">
                  <i class="iconfont icon-daxiaoxiezhuanhuan" :style="{
                      color: nameEnSwitchBol ? '#1890ff' : '#ccc',
                      cursor: 'pointer',
                    }" @click="nameEnSwitchFn"></i>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 中文名称 -->
            <el-form-item :label="$t('productDetail.ChineseName')" prop="nameCn" class="w100">
              <!-- 请输入长度1到100个字符 -->
              <el-input v-model="form.nameCn" :placeholder="$t('placeholder.M2001')" maxlength="100" show-word-limit
                class="test" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- 如果选择的类别是关联产线的，必填的 -->
          <el-col :span="8">
            <!-- 产品类型 -->
            <el-form-item :label="$t('productDetail.ProductType')" prop="standardCustomized" class="w100">
              <!-- 请选择 -->
              <el-select disabled v-model="form.standardCustomized"
                :placeholder="$t('placeholder.plsSel')" class="w100" @change="standardCustomizedChange">
                <el-option v-for="(item, index) in proTypeData" :key="item + index" :value="item.nameCn"
                  :label="$t(item.codeName)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 产品类别 -->
            <el-form-item class="w100" :label="$t('productDetail.ProductCategory')" prop="categoryId">
              <el-cascader ref="cascaderAddr" :options="categoryOptions" v-model="form.categoryId"
                @change="proCategoryChange" :props="optionProps" :placeholder="$t('placeholder.plsSel')"
                filterable></el-cascader>
            </el-form-item>
          </el-col>

          <!-- 产线 -->
          <el-col :span="8">
            <!-- 标准品-产线必填 -->
            <el-form-item :label="$t('productDetail.ProductionLine')" prop="productionLine" class="w100" :rules="{
                required: productionLineBol,
                trigger: 'change',
                message: $t('rules.M2013'),
              }">
              <el-select v-model="form.productionLine" :placeholder="$t('placeholder.plsSel')" class="w100"
                @change="proLineChange" :disabled="lineChoose" filterable>
                <el-option v-for="(item, index) in proLineData" :key="item + index" :label="
                    settings === 'zh' ? item.lineAndNameCN : item.lineAndNameEN
                  " :value="item.productSettingId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <!-- 产品运营 -->
            <el-form-item :label="$t('productDetail.ProductOperations')" prop="belonger" class="w100" :rules="[
                {
                  required: ProductOperationsBol,
                  message: $t('placeholder.plsSel'),
                  trigger: 'change',
                },
              ]">
              <!-- 选择相关人员 -->
              <el-select v-if="!showChooseUser" v-model="form.belonger" :placeholder="$t('placeholder.plsSel')"
                class="w100" @change="belongerChange">
                <el-option v-for="(item, index) in belongData" :key="item + index"
                  :label="settings === 'en' ? item.userNameEn : item.userName" :value="item.userName"></el-option>
              </el-select>

              <!-- 选择组织架构 -->
              <el-input v-if="showChooseUser" @focus="businessChange" :placeholder="$t('placeholder.plsSel')"
                v-model="form.belonger" clearable @change="clearBusinessName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 材质 -->
            <el-form-item :label="$t('productDetail.Materials')" prop="material" class="w100">
                <el-input v-model="form.material" :placeholder="$t('placeholder.plsInput')" maxlength="50"
                show-word-limit @input="materialblur"  @change="materialChange">
                <template slot="append">
                  <i class="iconfont icon-daxiaoxiezhuanhuan" :style="{
                      color: materialSwitchBol ? '#1890ff' : '#ccc',
                      cursor: 'pointer',
                    }" @click="materialSwitchFn"></i>
                </template>
              </el-input>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 单位 -->
            <el-form-item :label="$t('productDetail.Units')" prop="productUnit" class="w100">
              <el-select v-model="form.productUnit" :placeholder="$t('placeholder.plsSel')" class="w100">
                <el-option v-for="(item, index) in proUnitData" :key="item + index" :label="item.nameEn"
                  :value="item.nameEn"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-row :gutter="15">
              <el-col>
                <el-form-item class="w100">
                  <template slot="label">
                    <!-- 供应商 -->
                    <i style="color: #ff4d4f">*</i>
                    {{ $t('other.supplier') }}
                  </template>
                  <div v-if="supplier.length > 0">
                    <el-tag v-for="(it) in supplier" :key="it.supplierId" size="small">
                      {{ it.supplierName }}
                    </el-tag>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <!-- 英文描述 -->
            <el-form-item :label="$t('productDetail.EnglishDescription')" prop="descriptionEn" class="w100">
              <el-input v-model="form.descriptionEn" :placeholder="$t('placeholder.M2004')" maxlength="1000"
                show-word-limit class="input-textarea" style="white-space: pre-wrap" type="textarea"  resize="none"
                :autosize="{ minRows: 2 ,maxRows: 4}" @input="textareablur" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <!-- 关键词 -->
            <el-form-item :label="$t('productDetail.Keywords')" prop="keyWord" class="w100" style="margin-bottom: 8px">
              <el-input v-model="keyWordValue" @input="keyWordOninput" type="text"
                :placeholder="$t('placeholder.plsInput')" style="white-space: pre-wrap" clearable show-word-limit
                @keyup.enter.native="addkeyWordFn" @blur="keyWordBlur" />
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin-left: 10px">
            <el-button plain @click="addkeyWordFn" :style="{ marginTop: settings === 'zh' ? '0px' : '30px' }">
              {{ $t('other.Add') }}
            </el-button>
          </el-col>
          <el-col :span="24" :style="{
              paddingLeft: settings === 'zh' ? '110px' : '0px',
              marginBottom: '8px',
            }">
            <strong>{{ keyWordNum }}</strong>
            /200&nbsp;&nbsp;({{ $t('productDetail.keywordsTips') }})
          </el-col>
          <el-col :span="24" :style="{
              paddingLeft: settings === 'zh' ? '110px' : '0px',
              marginBottom: '20px',
            }">
            <el-tag class="keyWord-tag" v-for="tag in keyWordTags" :key="tag" closable @close="handleClose(tag)"
              style="white-space: pre-wrap; height: auto">
              {{ tag }}
            </el-tag>
          </el-col>
        </el-row>
        <el-row v-if="isChineseDesc">
          <!-- 中文描述 -->
          <el-col :span="24">
            <el-form-item :label="$t('productDetail.ChineseDescription')" class="w100">
              <el-input v-model="form.descriptionCn" type="textarea" maxlength="1000" show-word-limit
                style="white-space: pre-wrap" :autosize="{ minRows: 2, maxRows: 4 }"  resize="none" :placeholder="$t('placeholder.M2004')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row :style="{ marginLeft: settings === 'zh' ? '110px' : '0px' }" type="flex" align="middle">
        <!-- 标签设置 -->
        <el-button @click="labelApplyClick" type="primary">
          {{ $t('productDetail.LabelSettings') }}
        </el-button>
        <el-row class="ml30">
          <!-- 中文描述 -->
          <label class="mr10">
            {{ $t('productDetail.ChineseDescription') }}
          </label>
          <el-switch v-model="isChineseDesc" @change="chineseDescChange"></el-switch>
        </el-row>
        <!-- 开启多规格 -->

        <!-- <el-row class="ml30">
          <label class="mr10">
            {{ $t('productDetail.EnableMultipleSpecifications') }}
          </label>
          <el-switch v-model="isSpecs" @change="specsChange"></el-switch>
        </el-row> -->
        <el-row class="ml30">
          <!-- 客户信用额度适用 -->
          <label class="mr10">
            {{ $t('productDetail.CustomerCreditLimitsApplicable') }}
          </label>
          <el-switch v-model="form.creditLimit" :active-value="1" :inactive-value="0"></el-switch>
        </el-row>
      </el-row>
    </el-col>
    <!-- 供应商列表弹框 -->
    <SupplierListModel ref="supplierListModel" @supplierSelect="supplierSelect" />
    <!-- 供应商新增弹框 -->
    <SupplierAddModel ref="supplierAddModel" @afterSave="afterSave" />

    <!-- 标签设置弹框 -->
    <ProLabelApplyModel ref="proLabelApplyModel" @productKeyEditDTOList="productKeyEditDTOList" />

    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </el-row>
</template>
<script>
  import { categoryListForSelect } from '@/api/product/productPrintWay'
  import { getDetailByType } from '@/api/product/productSetting'
  import { listForPopupByCategoryId } from '@/api/product/labelManage'
  import { listUserForProductionLine } from '@/api/product/productList'
  import { elFormErrorScrollIntoView } from 'kits'
  import { deepCopy } from 'kits'
  import SupplierListModel from './SupplierListModel'
  import SupplierAddModel from './SupplierAddModel'
  import ProLabelApplyModel from './ProLabelApplyModel'
  import { mapGetters } from 'vuex'
  import UserChoose from '@/components/userChoose'
  function getCascaderObj(val, opt) {
    return val.map(function (value, index, array) {
      for (var itm of opt) {
        if (itm.productCategoryId === value) {
          opt = itm.children
          return itm
        }
      }
      return null
    })
  }
  export default {
    name: 'ProBaseMsg',
    props: {
      editData: {
        type: Object,
        required: false,
      },
      standardBol: {
        type: Boolean,
      },
    },
    data() {
      return {
        vals: [],
        nameEnSwitchBol: true, //英文名称开启关闭大小写
        materialSwitchBol:true,//材质开启关闭大小写
        originalNameEn: '', // 用于保存初始输入的值
        keyWordValue: '', //关键词
        form: {
          categoryId: [],
          creditLimit: 1,
          createWay: 0, //创建方式(0自行新增,1生产系统,2询盘生成)
          standardCustomized: '', //
          productUnit: '',
          belongerId: '',
          belonger: '',
          belongerEn: '',
          productionLineId: '',
          productionLine: '',
          productionLineEn: '',
          keyWord: '',
        },
        supplierBol: true, // 供应商是否可点击
        categoryOptions: [], //产品类别
        // optionProps: {
        //   value: 'productCategoryId',
        //   label: this.settings === 'zh' ? 'nameCn' : 'nameEn',
        //   children: 'children',
        //   checkStrictly: true,
        // },
        proMaterialData: [],
        materialBol: false, //材质是否可以下拉（需先选择产品类别）
        categoryId: '', //类别id，根据id查材质，供应商列表，供应商新增
        materialId: '', //材质id，（+类别id）查询印刷,
        labelList: [],
        isChineseDesc: false, //是否展示中文描述
        isSpecs: true, //开启多规格
        creditLimit: 1, //客户信用额度适用(0否1是)
        proUnitData: [], //单位下拉6
        proTypeData: [], //产品类型7
        proLineData: [], //产线下拉9
        productionLineBol: true, //当产品类型为标准品时，产线必填
        labelChooseBol: false, //是否有标签且必填
        ProductOperationsBol: false,
        categoryIdLocal: '', //用于本地存储产品类别
        belongData: [], //产品运营下拉
        belongDataOption: [],
        lineChoose: false, //产线可选
        proLineDataOption: [],
        rules: {},
        canEditBelonger: false,
        lastProduct: null, //上一次选择的产线
        lastDetail: null,
        supplier: [], // 供应商数据

        showChooseUser: false, // 产线运营 显示组织架构
        standardCustomizedDisabled: false, //
        material:"" // 材料
      }
    },
    watch: {
      // 'form.standardCustomized': {
      //   deep: true,
      //   immediate: true,
      //   handler(val) {
      //     if (!val) return
      //     val === '标准品' || val === '订制品'
      //       ? (this.productionLineBol = true)
      //       : (this.productionLineBol = false)
      //   },
      // },
      settings(val) {
        this.init()
        if (this.cacheBelonger) {
          var { belongerEn, belonger } = this.cacheBelonger
          this.form.belonger = val === 'en' ? belongerEn : belonger
        }
        // 产线运营 1.5.5.2
        if (this.currentProductLine) {
          var { nameCn, nameEn, belongerName, belongerNameEn } =
            this.currentProductLine
          this.form.productionLine =
            val === 'en'
              ? `${nameEn}${belongerNameEn ? '(' + belongerNameEn + ')' : ''}`
              : `${nameCn}${belongerName ? '(' + belongerName + ')' : ''}`
        }
      },
    },

    components: {
      SupplierListModel,
      SupplierAddModel,
      ProLabelApplyModel,
      UserChoose,
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      optionProps() {
        return {
          value: 'productCategoryId',
          label:
            this.settings === 'zh'
              ? 'nameCn'
              : this.settings === 'en'
                ? 'nameEn'
                : '',
          children: 'children',
          // checkStrictly: true,
        }
      },
      // 关键词展示
      keyWordTags() {
        return this.form.keyWord ? this.form.keyWord.split(',') : []
      },
      // 关键词长度
      keyWordNum() {
        let len = this.form?.keyWord?.split(',')?.length ?? 0
        len = len ? len - 1 : 0
        if (this.form?.keyWord) {
          let num = this.form.keyWord.length + len + 2
          if (num === 201 || num === 202) {
            return 200
          } else {
            return num
          }
        } else {
          return 0
        }
      },
      // 设置标准品标识
      standardBolValue: {
        get() {
          return this.standardBol
        },
        set(val) {
          this.$emit('update:standardBol', val)
        },
      },
    },
    created() {
      this.init()
      this.initRules()
    },
    mounted() { },
    methods: {
      // 添加关键词
      keyWordBlur() {
        if (this.keyWordValue) {
          this.$nextTick(() => {
            this.$refs.form.clearValidate('keyWord')
          })
        }
      },
      addkeyWordFn() {
        if (!this.keyWordValue) {
          this.$baseMessage(
            this.$t('placeholder.plsInput'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return
        }
        const pattern = /(?:^|\s|[^a-zA-Z])(and|or)(?:\s|[^a-zA-Z]|$)/gi
        if (pattern.test(this.keyWordValue)) {
          this.$baseMessage(
            this.$t('placeholder.noAndOr'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return
        }
        let inputValue = JSON.parse(JSON.stringify(this.keyWordValue))
        inputValue = inputValue
          .replace(/\s*,\s*/g, ',')
          .replace(/,{2,}/g, ',')
          .replace(/,(?=\s*$)/g, '')
        let inputValueArr = inputValue.split(',')
        inputValueArr = inputValueArr.map((item) => item.trim())
        let lengBol = inputValueArr.some((item) => item.length > 35)
        this.$refs.form.clearValidate('keyWord')
        if (lengBol) {
          this.$baseMessage(
            this.$t('placeholder.max35'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return
        }

        let keyWordArr = this.form.keyWord ? this.form.keyWord.split(',') : []
        let repeatBol = inputValueArr.some((item) => {
          return keyWordArr.includes(item)
        })
        if (this.hasDuplicates(inputValueArr) || repeatBol) {
          this.$baseMessage(
            this.$t('productCenter.KeywordAlreadyExists'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return
        }
        let inputValueStr = inputValueArr.join(', ')
        let len = this.form.keyWord && this.form.keyWord.split(',').length
        len = len ? len - 1 : 0
        let maxNum = this.form.keyWord?.length ? 198 : 200
        if (
          inputValueStr.length + this.form.keyWord?.length >
          maxNum - Number(len)
        ) {
          this.$baseMessage(
            this.$t('placeholder.max200'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return
        }

        this.form.keyWord = this.form.keyWord
          ? this.form.keyWord + ',' + this.keyWordValue.trim()
          : this.keyWordValue.trim()
        this.form.keyWord = this.form.keyWord
          .replace(/\s*,\s*/g, ',')
          .replace(/,{2,}/g, ',')
          .replace(/,(?=\s*$)/g, '')
        this.keyWordValue = ''
      },
      //判断有没有重复项
      hasDuplicates(arr) {
        let seen = {}
        for (var i = 0; i < arr.length; i++) {
          if (seen[arr[i]]) {
            return true
          }
          seen[arr[i]] = true
        }
        return false
      },
      // 删除关键词
      handleClose(tag) {
        let arr = this.form.keyWord.split(',')
        const idx = arr.findIndex((item) => item === tag)
        arr.splice(idx, 1)
        this.form.keyWord = arr.join(',')
      },
      // 关键词输入现在
      keyWordOninput(val) {
        if (!val) return
        let reg = new RegExp('^[\u4E00-\u9FA5]*$')
        if (reg.test(val) || /^\s+$/.test(val)) {
          return (this.keyWordValue = '')
        }
        if (!reg.test(val)) {
          let value = val.replace(/[\u4E00-\u9FA5]/g, '')
          this.keyWordValue = value.replace(/(?:^|\s|,)\w/g, (match) => {
              return match.toUpperCase();
          });


        }
      },
      init() {
        //产品分类下拉
        this.getCategoryListForSelect()
        //单位下拉
        this.getDetailByType6()
        //产品类型下拉
        this.getDetailByType7()
        //产线下拉
        this.getDetailByType9()
        this.$Bus.$on('getSellerEnCnConfig', (data) => {
          this.material = data.find((o) => {
            return o.attributeType === 'material'
          }).attributeValueEn
        })
      },
      initRules() {
        let obj = {
          nameCn: [
            // 请输入中文名称
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          nameEn: [
            {
              required: true,
              // message: '请输入英文名称',
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          // 请选择产品类型
          standardCustomized: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          categoryId: [
            // 请选择产品类别
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          material: [
            {
              required: true,
              // message: '请输入材质',
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              validator: this.$formValidation.englishName,
              trigger: 'blur',
            },
          ],
          // 请填写关键词
          keyWord: [
            {
              required: true,
              message: this.$t('placeholder.pleaseAddIn'),
              trigger: 'blur',
            },
          ],
          productUnit: [
            // 请选择单位
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          descriptionEn: [
            // 请输入英文描述
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        }
        this.rules = { ...obj }
      },

      //产线选择
      proLineChange(val) {
        this.showChooseUser = false
        if (!val) {
          this.belongData = []
        }
        this.canEditBelonger = false
        this.form.belongerId = ''
        this.form.belonger = ''
        this.form.belongerEn = ''
        // if (this.form.standardCustomized == '标准品') {
        // this.$set(this.form, 'belonger', '')
        // this.$set(this.form, 'belongerId', '')
        // }
        if (this.form.standardCustomized == '订制品' && val) {
          this.ProductOperationsBol = true
        }
        //清除归属人，归属人id
        let obj = this.proLineData.filter(
          (item) => val === item.productSettingId
        )
        this.lastProduct = { ...obj[0] }

        // 1.5.5.1添加 jyq start
        this.currentProductLine = JSON.parse(JSON.stringify({ ...obj[0] }))
        this.form.productionLine =
          this.settings === 'zh' ? obj[0].lineAndNameCN : obj[0].lineAndNameEN
        // 1.5.5.1添加 jyq end
        this.form.productionLineId = obj[0].productSettingId

        if (
          this.form.standardCustomized === '订制品' &&
          obj[0].compulsoryAssistance === 0
        ) {
          this.form.belonger =
            this.settings === 'en'
              ? this.userInfo.englishName
              : this.userInfo.userName

          this.form.belongerId = this.userInfo.userId
          this.canEditBelonger = true
          this.showChooseUser = true
        } else {
          this.getListUserForProductionLine(val)
          this.canEditBelonger = false
          this.showChooseUser = false
        }

        /*
            若产线有值，可选择产品运营人，
            数据源——下拉选择，从产线归属人、成员中选择，
            若只有1人，默认带出
          */
      },
      //产品运营下拉
      async getListUserForProductionLine(productSettingId) {
        let response = await listUserForProductionLine({
          productSettingId: productSettingId,
        })
        if (response?.code === '000000') {
          this.belongData = response.data
          this.setDefaultBelonger()
        }
      },
      // 设置默认产品运营
      setDefaultBelonger() {
        if (
          this.belongData.length == 1 &&
          !(
            this.lastDetail &&
            this.lastDetail.compulsoryAssistance === 0 &&
            this.form.standardCustomized === '订制品'
          )
        ) {
          this.form.belonger = this.belongData[0].userName
          this.form.belongerId = this.belongData[0].userId
        }
      },

      //归属人选择
      belongerChange(val) {
        let belonger = this.belongData.find((item) => val === item.userName)
        this.form.belongerId = belonger.userId
        // this.form.belonger = this.settings === 'en' ? this.userInfo.userNameEn :this.userInfo.userName
        this.form.belonger =
          this.settings === 'en' ? belonger.userNameEn : belonger.userName
        this.cacheBelonger = {
          belonger: belonger.userName,
          belongerEn: belonger.userNameEn,
        }
      },

      //产品类型选择
      standardCustomizedChange() {
        this.ProductOperationsBol =
          this.form.standardCustomized === '订制品' && this.form.productionLine
            ? true
            : false
        // this.productionLineBol =
        //   this.form.standardCustomized === '标准品' ? true : false
        if (this.form.standardCustomized === '标准品') {
          this.productionLineBol = true
        } else {
          this.productionLineBol = false
          this.$nextTick(() => {
            this.$refs.form.clearValidate('productionLine')
          })
        }
        this.ProductOperationsBol =
          this.form.standardCustomized === '标准品' ? true : false
        if (this.canEditBelonger) {
          this.canEditBelonger = false
          this.form.belongerId = ''
          this.form.belonger = ''
          this.form.belongerEn = ''
        }
        this.getDetailByType9()

        this.form.productionLine = ''
        this.form.productionLineId = ''
        this.form.belonger = ''
        this.form.belongerId = ''
        this.form.belongerEn = ''
        this.lineChoose = false
        this.standardBolValue = this.form.standardCustomized === '标准品'
        // this.$Bus.$emit('setProductType', this.form.standardCustomized)
      },
      //中文描述开启
      chineseDescChange(val) {
        if (!val) {
          this.form.descriptionCn = ''
        }
      },
      //开启单规格/双规格
      specsChange(val) {
        this.$Bus.$emit('specsChange', val)
      },
      textareablur(val) {
        if (!val) return
        let reg = new RegExp('^[\u4E00-\u9FA5]*$')
        if (reg.test(val)) {
          return (this.form.descriptionEn = '')
        }
      },
      // 英文名称开启大小写
      nameEnSwitchFn() {
        this.nameEnSwitchBol = !this.nameEnSwitchBol
        if (this.nameEnSwitchBol) this.originalNameEn = this.form.nameEn
      },
      nameEnblur(val) {
        if (!val) return

        // 扩展的正则表达式，包含更多的中文符号和标点，包括中文引号
        let reg = new RegExp(
          '^[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]*$'
        )

        // 如果全是中文符号，将 form.nameEn 设为空
        if (reg.test(val)) {
          this.form.nameEn = ''
          return
        }

        // 如果包含中文符号
        let processedVal = ''

        if (this.nameEnSwitchBol) {
          if (val.includes(this.originalNameEn)) {
            processedVal = val
              .replace(this.originalNameEn, '')
              .replace(
                /[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]/g,
                ''
              )
              .replace(/\b\w+\b/g, (word) => {
                return word.substring(0, 1).toUpperCase() + word.substring(1)
              })
            processedVal = this.originalNameEn + processedVal
          } else {
            processedVal = val
              .replace(
                /[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]/g,
                ''
              )
              .replace(/\b\w+\b/g, (word) => {
                return word.substring(0, 1).toUpperCase() + word.substring(1)
              })
          }
        } else {
          processedVal = val.replace(
            /[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]/g,
            ''
          )
        }

        // 将处理后的值保存在 form.nameEn 中
        this.form.nameEn = processedVal
      },

      async editShow(editData) {
        editData.material = this.material
        this.standardCustomizedDisabled = false
        let self = this
        if (editData.standardCustomized === '订制品') {
          this.standardBolValue = false
          this.productionLineBol = false
          // 定制品 有产线 必填
          this.ProductOperationsBol = false
          if (editData.productionLineId) {
            this.ProductOperationsBol = true
          }
        }
        if (editData.standardCustomized === '标准品') {
          this.standardBolValue = true
          this.ProductOperationsBol = true
          this.productionLineBol = true
          // 当产品审核通过后，如果选择类型为“标准品”，产品类型下拉选项直接禁用，不允许修改
          if (editData.auditHistory > 0 && this.$route.query.str == 'edit') {
            this.standardCustomizedDisabled = true
          }
        }

        if (editData) {
          editData.keyWord =
            editData.keyWord &&
            editData.keyWord
              .replace(/, /g, ',')
              .replace(/\n/g, '')
              .trim()
              .replace(/,$/, '')
          this.form = deepCopy(editData)
          if (this.form.productId || this.form.categoryId) {
            // this.getListForPopupByCategoryId(editData.categoryId?.split(',').slice(-1)[0])
            if (this.form.categoryId && this.form.categoryId.length) {
              this.categoryId = this.form.categoryId
              this.form.categoryId = this.form.categoryId.split(',')
              this.categoryIdLocal = this.form.categoryId

              let categoryId =
                this.categoryId.split(',')[
                this.categoryId.split(',').length - 1
                ]
              this.materialBol = true
              this.supplierBol = false

              if (
                this.form.productKeyEditVOList &&
                this.form.productKeyEditVOList.length
              ) {
                this.labelList = this.form.productKeyEditVOList
              } else {
                this.labelList = this.form.productKeyEditDTOList
              }
              this.labelList?.forEach((item) => {
                if (!item.productKeyEditId) {
                  item.productKeyEditId = item.productKeyId
                }
              })
            }

            //开启中文描述
            if (this.form.descriptionCn) {
              this.isChineseDesc = true
            }
            //单规格还是多规格
            if (this.form.specsType) {
              this.isSpecs = true
              this.$Bus.$emit('specsChange', this.isSpecs, 'edit')
            } else {
              this.isSpecs = false
            }
          }

          if (this.$route.query.str === 'clone') {
            this.form.productionLine = ''
            this.form.productionLineId = ''
            this.form.belonger = ''
            this.form.belongerId = ''
            this.form.belongerEn = ''
            // this.form.standardCustomized = '订制品'
            if (this.form.standardCustomized == '订制品') {
              this.productionLineBol = false
            }
            if (this.form.standardCustomized == '标准品') {
              this.productionLineBol = true
            }
            // // 定制品 有产线 必填
            // this.ProductOperationsBol = true
            // this.form.belonger = this.userInfo.userName
            // this.form.belongerId = this.userInfo.userId
          }

          if (this.$route.query.str === 'edit') {
            // this.ProductOperationsBol = true
            self.form.belonger =
              self.settings === 'en' ? editData.belongerEn : editData.belonger
            // 缓存belonger和belongerEn在不更改运营下拉切换语言模式时回显正确

            this.cacheBelonger = {
              belongerEn: editData.belongerEn,
              belonger: editData.belonger,
            }
            this.currentProductLine = {
              nameCn: `${editData.productionLine?editData.productionLine:''}${editData.productionLineOperation
                  ? '(' + editData.productionLineOperation + ')'
                  : ''
                }`,
              nameEn: `${editData.productionLineEn?editData.productionLineEn:''}${editData.productionLineOperationEn
                  ? '(' + editData.productionLineOperationEn + ')'
                  : ''
                }`,
            }

            this.form.productionLine =
              this.settings === 'zh'
                ? `${editData.productionLine?editData.productionLine:''}${editData.productionLineOperation
                  ? '(' + editData.productionLineOperation + ')'
                  : ''
                }`
                : `${editData.productionLineEn?editData.productionLineEn:''}${editData.productionLineOperationEn
                  ? '(' + editData.productionLineOperationEn + ')'
                  : ''
                }`

            self.form.belongerId = editData.belongerId
          }

          this.getDetailByType9()

          //设置供应商
          this.setSupliers(editData)

          //  产品运营  编辑时 产品运营下拉选项
          let response = await listUserForProductionLine({
            productSettingId: editData.productionLineId,
          })
          if (response.code === '000000') {
            this.belongData = response.data
          }

          if (
            this.form.productionLineId == '' &&
            this.form.standardCustomized == '订制品'
          ) {
            this.showChooseUser = true
            this.ProductOperationsBol = true
            this.showChooseUser = true
          } else {
            this.showChooseUser = false
          }

          // this.$Bus.$emit('setProductType', this.form.standardCustomized)

          this.lastProduct = this.proLineData.find(
            (it) => it.productSettingId === this.form.productionLineId
          )
        }
      },

      // 设置供应商
      setSupliers(detail) {
        this.supplier = []
        let ids = detail.supplierIds
          ? Array.from(new Set(detail.supplierIds.split(',')))
          : []
        let names = detail.supplierNames ? detail.supplierNames.split(',') : []
        ids.forEach((it, i) => {
          let obj = {
            supplierId: it,
            supplierName: names[i],
            main: ids.indexOf(detail.mainSupplierIds) > -1 ? true : false,
          }
          this.supplier.push(obj)
        })
      },

      async getCategoryListForSelect() {
        let response = await categoryListForSelect()
        if (response?.code == '000000') {
          this.categoryOptions = response.data
        }
      },
      //单位下拉
      async getDetailByType6() {
        let response = await getDetailByType({ type: 6 })
        if (response?.code === '000000') {
          this.proUnitData = response.data
        }
      },
      //产品类型接口（标准品订制品）
      async getDetailByType7() {
        let response = await getDetailByType({ type: 7 })
        if (response?.code === '000000') {
          this.proTypeData = response.data.map((item) => {
            return {
              ...item,
              codeName:
                item.nameCn === '标准品'
                  ? 'productCenter.StandardProduct'
                  : 'productCenter.CustomizedProduct',
            }
          })
        }
      },
      //产线下拉
      async getDetailByType9() {
        let response = await getDetailByType({ type: 9 })
        if (response?.code === '000000') {
          this.proLineData = response.data
          this.proLineDataOption = response.data.map((item) => {
            return {
              ...item,
              lineAndNameCN: `${item.nameCn ? item.nameCn : ''}${item.belongerName ? '(' + item.belongerName + ')' : ''
                }`,
              lineAndNameEN: `${item.nameEn ? item.nameEn : ''}${item.belongerNameEn ? '(' + item.belongerNameEn + ')' : ''
                }`,
            }
          })
          this.proLineData = this.proLineDataOption.filter(
            (item) =>
              item.categoryId.indexOf(
                this.form.categoryId &&
                this.form.categoryId[this.form.categoryId.length - 1]
              ) > -1
          )
          if (this.proLineData.length == 0) {
            this.proLineData = this.proLineDataOption

            if (this.form.standardCustomized === '订制品') {
              this.ProductOperationsBol = false
              this.productionLineBol = false
            }
          } else if (
            this.proLineData.length != 0 &&
            this.form.standardCustomized == '订制品'
          ) {
            this.productionLineBol = true
          }
          let obj = this.proLineData.filter((item) => {
            // return item.nameCn === this.editData.productionLine
            return item.productSettingId === this.editData.productionLineId
          })
          if (
            obj[0]?.compulsoryAssistance === 0 &&
            this.editData.standardCustomized === '订制品'
          ) {
            this.canEditBelonger = true
          }
          if (this.$route.query.str === 'clone') {
            this.canEditBelonger = false
          }
          let obj_1 = this.proLineData.find((it) => {
            return it.productSettingId == this.form.productionLineId
          })
          if (
            !obj_1 ||
            (obj_1 &&
              obj_1.compulsoryAssistance == 0 &&
              this.editData.standardCustomized === '订制品')
          ) {
            this.showChooseUser = true
            this.ProductOperationsBol = true
          } else {
            this.showChooseUser = false
          }
        }
      },
      // 产品类别选择
      proCategoryChange(val) {
        // 产品类别改变，产品运营清空
        this.form.belongerId = ''
        this.form.belonger = ''
        this.form.belongerEn = ''
        this.form.productionLine = ''
        this.form.productionLineEn = ''
        this.form.productionLineId = ''
        this.currentProductLine = {
          nameEn: '',
          nameCn: '',
        }
        this.belongData = []

        this.canEditBelonger = false
        // this.form.categoryId = val[val.length - 1]

        this.vals = getCascaderObj(this.form.categoryId, this.categoryOptions)
        let arr = []
        let arrEn = []
        let arrId = []
        this.vals.forEach((item) => {
          arr.push(item.nameCn)
          arrId.push(item.productCategoryId)
        })
        arrEn = this.vals.map((i) => i.nameEn)
        this.categoryIdLocal = arrId
        if (this.vals) {
          this.materialBol = true
          this.supplierBol = false
          //this.$set(this.form, 'material', '')
          this.categoryId = arrId.slice(-1).join(',')
          //this.form.categoryId = this.categoryId
          this.form.category = arr.join('/')
          this.form.categoryEn = arrEn.join('/')
          // this.getListMaterialByCategoryId(this.categoryId)
          this.$Bus.$emit('categoryId', this.categoryId)

          this.getListForPopupByCategoryId(this.categoryId)
          //('this.form------------', this.form)
        }
        //this.form.supplierNames = ''
        this.form.productKeyEditVOList = []
        this.form.productKeyEditDTOList = []

        // this.$forceUpdate()
        this.getProductLine()
        //产品类别切换清空印刷方式
        this.$Bus.$emit('changeCategoryId', this.categoryId)
      },
      // 根据选择的产品类别 确定产线和产品运营人
      getProductLine() {
        let _categoryId = this.form.categoryId[this.form.categoryId.length - 1]
        // this.proLineData = this.proLineDataOption.filter(
        //   (item) => item.categoryId.indexOf(_categoryId) > 0
        // )
        // 如果多个产品，必填、
        let obj = this.proLineDataOption.filter((item) => {
          return item.categoryId?.indexOf(_categoryId) > -1
        })
        // 一个产线，直接带出来，不可修改
        if (obj.length == 1) {
          this.lineChoose = true
          // this.form.productionLine = obj[0].nameCn
          this.form.productionLine = obj[0].lineAndNameCN
          this.form.productionLineId = obj[0].productSettingId
          this.currentProductLine = obj[0]
          this.getListUserForProductionLine(obj[0].productSettingId)
          if (
            obj[0]?.compulsoryAssistance === 0 &&
            this.form.standardCustomized === '订制品'
          ) {
            this.form.belongerId = this.userInfo.userId
            // this.form.belonger = this.userInfo.userName
            this.form.belonger =
              this.settings === 'en'
                ? this.userInfo.englishName
                : this.userInfo.userName
            this.canEditBelonger = true
            this.lastDetail = obj[0]
            this.showChooseUser = true
          } else {
            this.showChooseUser = false
          }
          this.lastProduct = { ...obj[0] }
        } else {
          this.lineChoose = false
          if (obj.length == 0 && this.form.standardCustomized === '订制品') {
            this.showChooseUser = true
          } else {
            this.showChooseUser = false
          }
          this.proLineData = obj.length == 0 ? this.proLineDataOption : obj
        }
        // 选择的类别是关联产线的，产线必填的
        if (this.form.standardCustomized === '订制品') {
          this.productionLineBol = obj.length ? true : false
          // 定制品 有产线 必填
          // this.ProductOperationsBol = obj.length ? true : false
          this.ProductOperationsBol = true
        }
        if (this.form.standardCustomized === '标准品') {
          this.productionLineBol = true
          this.ProductOperationsBol = true
        }
      },
      //查询标签
      async getListForPopupByCategoryId(categoryId, productKeyEditVOList) {
        let response = await listForPopupByCategoryId({
          categoryId: categoryId,
        })
        if ((response?.code === '000000')) {
          if (response?.data?.length) {
            if (
              (productKeyEditVOList && productKeyEditVOList.length) ===
              response.data.length
            ) {
              this.labelList = productKeyEditVOList
              response.data.forEach((item, index) => {
                if (
                  item.keyParentId !== productKeyEditVOList[index].parentKeyId
                ) {
                  this.labelList = []
                }
              })
            } else {
              this.labelList = []
            }

            response.data.forEach((item) => {
              if (item.required === '1') {
                this.labelChooseBol = true
              }
            })
          } else {
            this.labelChooseBol = false
          }
        }
      },
      supplierClick() {
        this.$refs['supplierAddModel'].supplierAddModel(this.categoryId)
      },
      // 供应商选择
      selectSupplierClick() {
        this.$refs['supplierListModel'].supplierListModel(
          this.form.supplierIds,
          this.form.supplierNames,
          this.form.mainSupplierIds
        )
      },
      // 供应商选择保存
      supplierSelect(selectRows) {
        this.supplier = selectRows.map((it) => {
          return {
            supplierName: it.supplierName,
            supplierId: it.supplierId,
            main: it.main,
          }
        })
        this.setSupplier()
      },
      setSupplier() {
        let supplierIds = [] //所有选中的供应商id
        let supplierNames = [] // 所有选中的供应商
        let mainSupplierIds = [] // 主要供应商id
        let mainSupplierName = [] // 主要供应商名称
        this.supplier.forEach((item) => {
          supplierNames.push(item.supplierName)
          supplierIds.push(item.supplierId)
          if (item.main) {
            mainSupplierIds.push(item.supplierId)
            mainSupplierName.push(item.supplierName)
          }
        })
        this.form.mainSupplierIds = mainSupplierIds.toString()
        this.form.mainSupplierName = mainSupplierName.toString()
        this.form.supplierIds = supplierIds.toString()
        this.$set(this.form, 'supplierNames', supplierNames.toString())
        this.$forceUpdate()
      },
      // 标签设置
      labelApplyClick() {
        if (this.categoryId === '') {
          // 请先选择产品类别
          return this.$baseMessage(
            this.$t('reqmsg.M2018'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        let categoryId = this.categoryId.split(',').slice(-1)[0]
        if (this.form.productId) {
          this.$refs['proLabelApplyModel'].proLabelApplyModel(
            categoryId,
            this.labelList
          )
        } else {
          //let label =  this.form.productKeyEditDTOList || this.form.productKeyEditVOList
          this.$refs['proLabelApplyModel'].proLabelApplyModel(
            categoryId,
            this.labelList
          )
        }
      },

      //标签数据
      productKeyEditDTOList(val) {
        this.labelList = val
      },

      // 供应商新增
      afterSave(msg) {
        let obj = {
          supplierId: msg.supplierId,
          supplierName: msg.supplierName,
          main: false,
        }
        this.supplier.push(obj)
        this.setSupplier()
      },
      // 校验单词是英文字母并且首字符是大写
      isFirstLetterUppercase(word) {
        const firstChar = word.charAt(0)
        if (/[a-zA-Z]/.test(firstChar)) {
          return /[A-Z]/.test(firstChar)
        } else {
          return true
        }
      },

      // 验证
      validateField() {
        let validFlag = false
        let success = true
        // 基本信息------start
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            const pattern = /(?:^|\s|[^a-zA-Z])(and|or)(?:\s|[^a-zA-Z]|$)/gi
            if (pattern.test(this.form.keyWord)) {
              this.$baseMessage(
                this.$t('placeholder.noAndOr'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
              return
            }
            if (this.form.keyWord && this.form.keyWord.length > 200) {
              this.$baseMessage(
                this.$t('placeholder.keywordMax200'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
              return
            }

            if (this.standardBolValue) {
              // 标准品
              const wordArr = this.form.keyWord.split(/[\s,]+/)
              const bol = wordArr.every((item) => {
                return this.isFirstLetterUppercase(item)
              })
              if (!bol) {
                this.$baseMessage(
                  this.$t('placeholder.keywordCapitalized'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
                return
              }
            }

            validFlag = true
          } else {
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.el-form-item__error')
            })
          }
        })
        if (validFlag) {
          let ruleForm = deepCopy(this.form)

          ruleForm.productionLine =
            this.currentProductLine && this.currentProductLine.nameCn
          ruleForm.productionLineEn =
            this.currentProductLine && this.currentProductLine.nameEn
          if (ruleForm.categoryId) {
            ruleForm.categoryId =
              ruleForm.categoryId[ruleForm.categoryId.length - 1].toString()
          }
          if (!this.form.supplierNames) {
            success = false
            // 请选择供应商
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.pro-base-msg')
            })
            this.$baseMessage(
              this.$t('reqmsg.M2019'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else if (this.labelChooseBol && !this.labelList) {
            success = false
            // 请配置产品标签
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.pro-base-msg')
            })
            this.$baseMessage(
              this.$t('reqmsg.M2021'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          } else if (this.labelChooseBol && this.labelList.length === 0) {
            success = false
            // 请配置产品标签
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.pro-base-msg')
            })
            this.$baseMessage(
              this.$t('reqmsg.M2021'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }

          if (ruleForm.productId && this.labelList && this.labelList.length) {
            this.labelList.forEach((item) => {
              item.productId = ruleForm.productId
            })
          }
          // 转换关键词为 逗号后面需要加一个空格
          ruleForm.keyWord =
            ruleForm.keyWord && ruleForm.keyWord.split(',').join(', ')
          return {
            success,
            data: ruleForm,
            productKeyEditDTOList: this.labelList,
            lastProduct: this.lastProduct,
          }
        } else {
          return {
            success: false,
          }
        }
        // 基本信息------end
      },
      //保存本地
      validateField2() {
        let ruleForm = deepCopy(this.form)
        // 1.5.5.1 start

        ruleForm.productionLine =
          this.currentProductLine && this.currentProductLine.nameCn
        ruleForm.productionLineEn =
          this.currentProductLine && this.currentProductLine.nameEn
        // 1.5.5.1 end
        if (ruleForm.categoryId && ruleForm.categoryId.length) {
          ruleForm.categoryId =
            ruleForm.categoryId[ruleForm.categoryId.length - 1].toString()
        } else {
          ruleForm.categoryId = ''
        }
        if (this.labelList && this.labelList.length === 0) {
          this.labelList =
            ruleForm.productKeyEditVOList || ruleForm.productKeyEditDTOList
        }
        ruleForm.keyWord =
          ruleForm.keyWord && ruleForm.keyWord.split(',').join(', ')
        return {
          data: ruleForm,
          productKeyEditDTOList: this.labelList,
          lastProduct: this.lastProduct,
        }
      },
      // 1.8.3 产线运营改版
      // https://www.tapd.cn/66577409/prong/stories/view/1166577409001000727
      businessChange() {
        this.$refs['UserChoose'].showAddEdit('', this.businessArray, true)
      },
      // 删除产线运营
      clearBusinessName() {
        this.form.belonger = ''
        this.form.belongerId = ''
        this.form.belongerEn = ''
      },
      chooseUser(val) {
        this.form.belonger = val[0].name
        this.form.belongerId = val[0].id
        this.form.belongerEn = val[0].englishName
      },
      // 材质改变
      materialChange(val) {
        this.$Bus.$emit('materialChange', val)
      },
      // 材质开启大小写
      materialSwitchFn() {
        this.materialSwitchBol = !this.materialSwitchBol
        if (this.materialSwitchBol) this.originalMaterial = this.form.material
      },

      materialblur(val) {
        if (!val) return

        // 扩展的正则表达式，包含更多的中文符号和标点，包括中文引号
        let reg = new RegExp(
          '^[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]*$'
        )

        // 如果全是中文符号，将 form.material 设为空
        if (reg.test(val)) {
          this.form.material = ''
          return
        }

        // 如果包含中文符号
        let processedVal = ''

        if (this.materialSwitchBol) {
          if (val.includes(this.originalMaterial)) {
            processedVal = val
              .replace(this.originalMaterial, '')
              .replace(
                /[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]/g,
                ''
              )
              .replace(/\b\w+\b/g, (word) => {
                return word.substring(0, 1).toUpperCase() + word.substring(1)
              })
            processedVal = this.originalMaterial + processedVal
          } else {
            processedVal = val
              .replace(
                /[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]/g,
                ''
              )
              .replace(/\b\w+\b/g, (word) => {
                return word.substring(0, 1).toUpperCase() + word.substring(1)
              })
          }
        } else {
          processedVal = val.replace(
            /[\u4E00-\u9FA5\u3000-\u303F\uff00-\uffef\u2018\u2019\u201C\u201D]/g,
            ''
          )
        }

        // 将处理后的值保存在 form.material 中
        this.form.material = processedVal
      },
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      align-items: center;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }
  }

  ::v-deep {
    .el-cascader {
      width: 100%;
    }

    .test {
      .el-input__inner {
        padding: 0 60px 0 15px;
      }
    }

    .el-textarea__inner {
      padding: 5px 50px 5px 10px;
    }
  }

  .keyWord-tag {
    margin: 0 10px 10px 0 !important;
  }
</style>
